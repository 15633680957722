$(document).on("turbolinks:load", () => {
  $(() => {
    const { pathname, search } = location;
    const activeNavBar = $(`a[href='${pathname}${search}']`);

    console.log(activeNavBar);

    activeNavBar.parent().addClass("active");

    $(".show-mobile-menu").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();

      $(".side-menu-novo").toggleClass("open");

      setTimeout(function () {
        $(".side-menu-novo.open .side-menu__nav-menu__item-text").fadeIn();
        $(".side-menu-novo.open .side-menu__nav-menu__item-tag").fadeIn();
        $(".side-menu__nav-menu__item-icon").toggleClass("icon-aberto");
      }, 550);

      $(".side-menu-novo .side-menu__nav-menu__item-text").fadeOut();
      $(".side-menu-novo .side-menu__nav-menu__item-tag").fadeOut();
      $(".show-mobile-menu").toggleClass("change-icon-menu");
    });
  });
});
