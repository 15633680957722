$(document).on("turbolinks:load", () => {
  $(() => {
    var currentTab = 0;

    showTab(currentTab);

    function showTab(n) {
      var x = document.getElementsByClassName("step");

      if (!x[n]) return;

      x[n].style.display = "block";

      if (n == 0) {
        $(".form-footer").css("justify-content", "end");

        document.getElementById("prevBtn").style.display = "none";
      } else {
        document.getElementById("prevBtn").style.display = "inline";
      }

      if (n == x.length - 1) {
        document.getElementById("nextBtn").innerHTML = "Enviar";
      } else {
        document.getElementById("nextBtn").innerHTML = "Próximo";
      }

      fixStepIndicator(n);
    }

    function nextPrev(n) {
      var x = document.getElementsByClassName("step");

      if (n == 1 && !validateForm()) return false;

      x[currentTab].style.display = "none";
      currentTab = currentTab + n;

      if (currentTab >= x.length) {
        $("#secondary").css("display", "none");

        sendRequest(data());
        window.location.href = "/levante";
      }

      showTab(currentTab);
    }

    function validateForm() {
      var x,
        y,
        i,
        valid = true;

      x = document.getElementsByClassName("step");
      y = x[currentTab].getElementsByTagName("input");

      for (i = 0; i < y.length; i++) {
        if (y[i].value == "") {
          y[i].className += " invalid";
          valid = false;
        }
      }

      if (valid) {
        document.getElementsByClassName("stepIndicator")[
          currentTab
        ].className += " finish";
      }
      return valid;
    }

    function fixStepIndicator(n) {
      var i,
        x = document.getElementsByClassName("stepIndicator");

      for (i = 0; i < x.length; i++) {
        x[i].className = x[i].className.replace(" active", "");
      }

      x[n].className += " active";
    }

    $(".next-page").on("click", () => {
      nextPrev(1);
      sendRequest(data());
    });

    $(".previous-page").on("click", () => nextPrev(-1));

    const data = () => {
      let data = {};
      let form = $("#signUpForm");
      let radios = form.find("input[type=radio]:checked");
      let check_content = $("input[name=investment_content]:checked");
      let investment_period = $("input[name=investment_period]:checked");
      let check_content_values = [];
      let investment_period_values = [];

      radios.each(function () {
        data[$(this).attr("name")] = $(this).val();
      });

      check_content.each(function () {
        check_content_values.push($(this).val());
      });

      investment_period.each(function () {
        investment_period_values.push($(this).val());
      });

      data["investment_content"] = check_content_values;
      data["investment_period"] = investment_period_values;
      data["step"] = currentTab;

      return data;
    };

    const sendRequest = (data) => {
      $.ajax({
        url: "/levante/preferences",
        type: "POST",
        data: data,
        success: () => $(".btn-survey2.next-page").attr("disabled", false),
        error: () => $(".btn-survey2.next-page").attr("disabled", true),
      });
    };
  });
});
