$(document).on("turbolinks:load", () => {
  $(() => {
    const invalidFeedback = $(".invalid-feedback");
    const alert = $(".alert-error");

    if (invalidFeedback.length) {
      invalidFeedback.css("display", "block");
      invalidFeedback.parent().addClass("is-invalid");

      alert.fadeTo(3500, 500).slideUp(500, () => alert.slideUp(500));
    }

    $("input[type='password']").val("");
  });

  const resetFormSettings = (one, two) => {
    one.addClass("d-none");
    two.removeClass("d-none");
  };

  $("#user_is_cnpj").on("change", function () {
    const formPf = $("#cpf");
    const formPj = $("#cnpj");

    if ($(this).is(":checked")) {
      resetFormSettings(formPf, formPj);
    } else {
      resetFormSettings(formPj, formPf);
    }
  });
});
