$(document).on("turbolinks:load", () => {
  moment.locale("pt-br");

  $.fn.dataTable.moment("DD/MM/YYYY HH:mm");
  $.fn.dataTable.moment("L", "pt-br");

  function distanceOfTimeInWordsToNow(date) {
    const now = new Date();
    const targetDate = new Date(date);
    const seconds = Math.floor((now - targetDate) / 1000);

    const intervals = {
      ano: 31536000,
      mes: 2592000,
      semana: 604800,
      dia: 86400,
      hora: 3600,
      minuto: 60,
      segundo: 1,
    };

    const colors = {
      ano: "#1d1e1f",
      mes: "#264663",
      semana: "#2d6aa3",
      dia: "#304d30",
      hora: "#2a782a",
      minuto: "#2f9e2f",
      segundo: "#2cc92c",
    };

    for (let interval in intervals) {
      const intervalInSeconds = intervals[interval];
      const intervalDuration = Math.floor(seconds / intervalInSeconds);

      if (intervalDuration >= 1) {
        const plural = intervalDuration !== 1 ? "s" : "";

        return `<span class="map-color" style="background-color: ${colors[interval]};">há ${intervalDuration} ${interval}${plural}</span>`;
      }
    }

    return `<span class="map-color" style="background-color: '#2cc92c'";>agora mesmo</span>`;
  }

  const orderBy = {
    bills: [[7, "desc"]],
    users: [[4, "desc"]],
    orders: [[6, "desc"]],
    categories: [[3, "asc"]],
    promotions: [[3, "asc"]],
    track_refunds: [[4, "desc"]],
    pending_orders: [[6, "desc"]],
    refund_reasons: [[3, "desc"]],
    recommended_wallets: [[0, "asc"]],
    refund_requested_bills: [[7, "desc"]],
  };

  $(".dtable").DataTable({
    paging: false,
    ordering: true,
    info: false,
    searching: false,
    language: {
      decimal: ",",
    },
    order: orderBy[location.pathname.split("/").pop()] || [[1, "desc"]],
  });

  $("th").click(function () {
    this.asc = !this.asc;
    if (this.asc === true) {
      $("th")
        .find(".table-order-icon")
        .removeClass("bi bi-sort-down")
        .addClass("bi-sort-down-alt");
    } else {
      $("th")
        .find(".table-order-icon")
        .removeClass("bi-sort-down-alt")
        .addClass("bi bi-sort-down");
    }
  });

  const ptBr = {
    language: {
      lengthMenu: "Mostrar registros por página _MENU_",
      zeroRecords: "Nenhum registro encontrado",
      info: "Mostrando página _PAGE_ de _PAGES_",
      infoEmpty: "Nenhum registro encontrado",
      infoFiltered: "(filtrado de _MAX_ registros)",
      emptyTable: "Nenhum registro encontrado",
      search: "Buscar:",
      loadingRecords: "Carregando...",
      processing: "Processando...",
      zeroRecords: "Nenhum registro encontrado",
      paginate: {
        previous: "Anterior",
        next: "Próximo",
      },
    },
  };

  $(".users-online").DataTable({
    order: [[5, "desc"]],
    ...ptBr,
  });

  const getAnalyticalData = () => {
    $(".analytical").DataTable({
      ajax: {
        url: "/admin/users/analytics",
        dataSrc: function (json) {
          removeLoading();

          return json.data;
        },
      },
      columns: [
        { data: "name" },
        { data: "email" },
        { data: "phone" },
        { data: "offer_name" },
        { data: "coupon_name" },
        { data: "created_at" },
        { data: "created_at", visible: false },
      ],
      columnDefs: [
        {
          targets: 0,
          render: function (data, _type, row) {
            return `<a href="/admin/users/${row.id}">${data}</a>`;
          },
        },
        {
          targets: 5,
          render: function (data) {
            return distanceOfTimeInWordsToNow(data);
          },
        },
        {
          targets: 4,
          render: function (data) {
            return data || "-";
          },
        },
      ],
      order: [[6, "desc"]],
      ...ptBr,
    });
  };

  const getSyntheticDate = () => {
    $(".synthetic").DataTable({
      ajax: {
        url: "/admin/users/synthetic",
        dataSrc: function (json) {
          removeLoading();

          return json.data;
        },
      },
      columns: [
        { data: "name" },
        { data: "email" },
        { data: "phone" },
        { data: "offer_name" },
        { data: "coupon_name" },
        { data: "qtd_visitas" },
      ],
      columnDefs: [
        {
          targets: 0,
          render: function (data, _type, row) {
            return `<a href="/admin/users/${row.id}">${data}</a>`;
          },
        },
        {
          targets: 4,
          render: function (data) {
            return data || "-";
          },
        },
      ],
      order: [[5, "desc"]],
      ...ptBr,
    });
  };

  const addLoadingIcon = (data) => $(data).find("i").toggleClass("bi-circle");

  const removeLoading = () => {
    setTimeout(() => {
      $(".active-checkout button[data-bs-toggle='tab']")
        .find("i")
        .removeClass("bi-circle")
        .addClass("bi-arrow-clockwise");

      $(".active-checkout button[data-bs-toggle='tab']").removeClass(
        "disabled"
      );
    }, 150);
  };

  getAnalyticalData();
  getSyntheticDate();

  $(".active-checkout button[data-bs-toggle='tab']").on("click", function () {
    $(this).addClass("disabled");
    addLoadingIcon(this);
  });

  $(".active-checkout button[data-bs-target='#nav-synthetic']").on(
    "click",
    () => {
      $(".synthetic").DataTable().ajax.reload();
    }
  );

  $(".active-checkout button[data-bs-target='#nav-analytical']").on(
    "click",
    () => {
      $(".analytical").DataTable().ajax.reload();
    }
  );
});
