$(document).on("turbolinks:load", () => {
  const checkBillOrder = $("#bill_order_exists").val();

  const inputRefundForm = () => {
    if ($("#refund_type").val() === "Integral") {
      return $("#refund_type, #refund_reason");
    } else {
      return $("#refund_type, #refund_message, #refund_reason, #refund_value");
    }
  };

  inputRefundForm().each(function () {
    $(this).on("change blur", function () {
      if ($(this).val() != "") {
        $(this).removeClass("is-invalid");
        $(this).addClass("is-valid");
      } else {
        $(this).removeClass("is-valid");
        $(this).addClass("is-invalid");
      }
    });
  });

  $("#refund_type").on("change", ({ target: { value } }) => {
    if (value === "Integral" && checkBillOrder === "true") {
      $("#refund_value_label").attr("hidden", true);
      $("#refund_value").attr("hidden", true);
    } else {
      $("#refund_value").attr("hidden", false);
      $("#refund_value_label").attr("hidden", false);
    }
  });

  $("#refund_submit").on("click", function () {
    const is_valid = validRefund();

    if (is_valid) $("#refund_form").submit();
  });

  function validRefund() {
    let is_valid = true;

    inputRefundForm().each(function () {
      if ($(this).val() != "") {
        $(this).removeClass("is-invalid");
        $(this).addClass("is-valid");
      } else {
        $(this).removeClass("is-valid");
        $(this).addClass("is-invalid");
        is_valid = false;
      }
    });

    return is_valid;
  }
});
