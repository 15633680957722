$(document).on("turbolinks:load", () => {
  const cepUtils = (data, response) => {
    $("#new_address, #nav-user-address, #user-address")
      .find("input")
      .map((_, val) => {
        const removeClass = ["is-invalid", "is-valid"];
        const resApi = [
          "cep",
          "city",
          "state",
          "neighborhood",
          "street",
          "numero",
        ];

        removeClass.forEach((c) => $(val).removeClass(c));

        resApi.forEach((val) => {
          const input = $(`input[placeholder='${val}']`);

          if (val === "state") input.prop("readonly", true);
          if (val === "city") input.prop("readonly", true);

          if (val !== "Cep" && val !== "Numero") {
            input.val(response ? response[val.toLocaleLowerCase()] : "");
          }

          val === "Numero" && input.val() === ""
            ? input.addClass("is-invalid")
            : input.addClass(data);
        });
      });
  };

  $("#order_address_number").on("blur change focusout", function () {
    const number = $(this);

    if (number.val() == "") {
      return number.removeClass("is-valid").addClass("is-invalid");
    }

    number.removeClass("is-invalid").addClass("is-valid");
  });

  $("#order_address_zipcode, #user_address_zipcode").on("input", function () {
    const value = $(this).val();

    if (!value || value.length < 8) {
      cepUtils("is-invalid", {});

      $("#new_address")
        .find("input")
        .map((_, val) => $(val).prop("readonly", false));

      return;
    }
  });

  $("#order_address_zipcode, #user_address_zipcode").on(
    "focusout",
    function () {
      const value = $(this).val();

      if (value == "") return;

      $.ajax({
        url: `https://brasilapi.com.br/api/cep/v1/${value}`,
        type: "GET",
        dataType: "json",
        cache: true,
        success: (data) => cepUtils("is-valid", data),
        error: () => cepUtils("is-invalid"),
      });
    }
  );
});
