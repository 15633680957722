import consumer from "./consumer";
import { getUrl } from "../packs/modules/common/url";

$(document).on("turbolinks:load", () => {
  const uuid = getUrl("uuid");

  if (!uuid) return;

  const channelPayload = {
    channel: "CheckoutPixChannel",
    uuid,
  };

  consumer.subscriptions.create(channelPayload, {
    connected() {
      console.log("Connected to the room!");

      setInterval(() => this.send({ type: "ping", uuid }), 9000);
    },
    disconnected() {
      console.log("Disconnected");
    },
    received(data) {
      window.location.href = `/levante/checkout/finished?uuid=${uuid}`;
    },
  });
});