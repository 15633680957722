$(document).on("turbolinks:load", () => {
  const change_icons = () => {
    icon = $(".favorite-icon").attr("class").includes("bi-star-fill");
    if (icon) {
      $(".favorite-icon").removeClass("bi-star-fill");
      $(".favorite-icon").addClass("bi-star");
    } else {
      $(".favorite-icon").removeClass("bi-star");
      $(".favorite-icon").addClass("bi-star-fill");
    }
  };

  const getIdsCheckbox = (array, filter = ":checked") => {
    return array
      .filter(filter)
      .map((_, value) => value.id)
      .get();
  };

  $(".favorite-button").on("click", () => {
    const tag_id = $(".favorite-button").attr("id");

    $.ajax({
      url: "/levante/favorites/set_favorite",
      type: "POST",
      data: { tag_id },
      success: () => change_icons(),
    });
  });

  $(".save-changes").on("click", function (e) {
    if ($(".nav-link.btn.active").attr("aria-selected") == "true" && $(".nav-link.btn.active").attr("aria-controls") == "nav-user-favorites") {
      e.preventDefault();

      const favoritesCheckBox = $('#nav-user-favorites input[type="checkbox"]');
      const ids = getIdsCheckbox(favoritesCheckBox);
      const unchecked_ids = getIdsCheckbox(favoritesCheckBox, ":not(:checked)");

      $.ajax({
        url: "/levante/favorites/set_all_favorites",
        type: "POST",
        data: { checked_ids: ids, unchecked_ids: unchecked_ids },
        success: () => showAlert(),
        error: (e) => {
          console.log("error", e);
        },
      });
    }
  });

  const showAlert = () => {
    const alert = $(".alert-favorites");

    alert.fadeTo(2000, 500)
      .slideUp(500, () => alert.slideUp(500));
  };
});
