import intlTelInput from "intl-tel-input";
import VMasker from "vanilla-masker";

$(document).on("turbolinks:load", () => {
  const input = document.querySelector("#user_phone");
  const phoneCode = $("#user_phone_code");
  const phoneCountry = $("#user_phone_country");

  if (!input) return;

  const iti = intlTelInput(input, {
    initialCountry: "auto",
    separateDialCode: true,
    preferredCountries: ["br", "pt", "us", "gb"],
    geoIpLookup: function (callback) {
      $.get("https://ipinfo.io", function () {}, "jsonp").always((resp) => {
        const countryCode = resp && resp.country ? resp.country : "br";
        callback(countryCode);
      });
    },
    utilsScript:
      "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/15.0.2/js/utils.js",
  });

  const checkPhone = () => {
    const inputPhone = $("#user_phone");

    if (inputPhone.attr("placeholder") == undefined) return;

    const expected = inputPhone.attr("placeholder").replace(/\D/gi, "");
    const currentValue = inputPhone.val().replace(/\D/gi, "");

    if (currentValue.length == expected.length) {
      return inputPhone.removeClass("is-invalid").addClass("is-valid");
    }

    inputPhone.removeClass("is-valid").addClass("is-invalid");
  };

  const handleIntlTel = (event) => {
    if (event == "init") iti.setNumber(`+${phoneCode.val() + input.value}`);

    const mask = input.placeholder.replace(/\d/g, 9);
    const { dialCode, iso2 } = iti.getSelectedCountryData();

    phoneCode.val(dialCode);
    phoneCountry.val(iso2);

    VMasker(input).maskPattern(mask);

    if (event == "change") checkPhone();
  };

  iti.promise.then(() => handleIntlTel("init"));

  input.addEventListener("input", () => checkPhone());
  input.addEventListener("countrychange", () => handleIntlTel("change"));
});
