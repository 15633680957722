$(document).on("turbolinks:load", () => {
  $("#offer_price").mask("#.##0,00", {
    reverse: true,
  });


  const applyTypeOnCoupon = (change = false) => {
    const type = $("#coupon_coupon_type option:selected").val();
    const input = $("#coupon_total_value");
    const mask = type == "fixed" ? "#.##0,00" : "##0,00%";

    if (change) input.val("");

    input.mask(mask, {
      reverse: true,
      onKeyPress: (val, _event, field, _options) => {
        if (type == "fixed") return;

        const value = val.replace(/,|%/g, "");

        if (+value > 10000 || val.length > 7) $(field).val("100,00%");
      },
    });
  };

  $("#coupon_coupon_type").on("change", () => applyTypeOnCoupon(true));

  applyTypeOnCoupon();
});
